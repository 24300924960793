<template>
    <div>
        <div class="workplatform-title">{{statement.code}}
            <i-button type="primary" class="right" size="small" @click="showPayments=true"> 查看回款记录</i-button>
        </div>

        <Row>
          <i-col span="12">
            <div v-if="statement.statementId" class="workplatform-title">甲方</div>
            <div v-if="statement.statementId" class="p-t-10 p-b-10">
                <Row>
                    <i-col span="24" ><span class="title">公司名称 </span>{{firstParty.name}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">纳税人识别号 </span>{{firstParty.code}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">地址电话 </span>{{firstParty.address}} {{firstParty.tel}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">开户行账号 </span>{{firstParty.bank}}  {{firstParty.bankAccount}}</i-col>
                </Row>
            </div>
          </i-col>
          <i-col span="12">
            <div v-if="statement.statementId" class="workplatform-title">乙方</div>
            <div v-if="statement.statementId" class="p-t-10 p-b-10">
                <Row>
                    <i-col span="24" ><span class="title">公司名称 </span>{{secondParty.name}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">纳税人识别号 </span>{{secondParty.code}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">地址电话 </span>{{secondParty.address}} {{secondParty.tel}}</i-col>
                </Row>
                <Row>
                    <i-col span="24" ><span class="title">开户行账号 </span>{{secondParty.bank}}  {{secondParty.bankAccount}}</i-col>
                </Row>
            </div>
          </i-col>
        </Row>

        <div class="workplatform-title">基础信息</div>
        <Row>
            <i-col span="8"><span class="title">总金额</span>
                <span class="money">{{formatMoney(statement.amount)}}</span> <span class="title">元</span>
            </i-col>
        </Row>
        <div class="workplatform-title">约定付款</div>
        <span class="title">按照约定，付款方必须在按照以下约定付款</span><br>
        <div v-for="(item,i) in statement.paymentBeanList" :key="i">
            <span class="title">在</span>
            <span class="text-orange">{{item.date}}</span><span class="title">之前付款</span>
            <span class="money">{{formatMoney(item.amount)}}</span> <span class="title">元</span><br>
        </div>

        <div class="workplatform-title m-t-10 m-b-10">申请记录</div>
        <i-table stripe  :data="applications" :columns="applicationColumns"></i-table>
        <div v-if="unInvoicedAmount&&isAuth('StatementInvoice_Edit')">
            <new-application :money="unInvoicedAmount" @on-update="handleApplicationUpdated" @on-submit="addApplication"></new-application>
        </div>

        <Modal v-model="showPayments" title="结算单回款记录" width="400" footer-hide>
            <assign-history :relateId="id" v-if="showPayments"></assign-history>
        </Modal>

    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { formatInvoiceType, formatInvoiceStatus } from '@/utils/tagStatus'

import newApplication from '@/components/contract/contractDetail/invoice/NewApplication'
import assignHistory from '@/components/contract/contractDetail/invoice/AssignHistory'

import { addInvoiceApplication, getInvoiceApplicationsList, invalidInvoiceApplication } from '@/api/scp/invoiceapplication'
import { getStatementDetail } from '@/api/scp/statement'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    }
  },
  components: {
    newApplication, assignHistory
  },
  data () {
    return {
      id: this.baseId,
      applications: [],
      applicationColumns: [
        { title: '时间', key: 'createTime' },
        {
          title: '申请金额(元)',
          render: (h, data) => {
            return h('span', { class: 'money' }, toMoney(data.row.amount))
          }
        },
        { title: '备注', key: 'content' },
        {
          title: '类型',
          render: (h, data) => {
            return formatInvoiceType(h, data.row.type, data.row.typeName)
          }
        },
        {
          title: '状态',
          render: (h, data) => {
            return formatInvoiceStatus(h, data.row.status, data.row.statusName)
          }
        }
      ],
      newApplications: [],
      statement: { },
      firstParty: {}, // 甲方信息
      secondParty: {}, // 乙方信息
      showPayments: false

    }
  },
  methods: {
    addApplication () {
      const applicationPromise = []
      this.newApplications.forEach(item => {
        applicationPromise.push(
          addInvoiceApplication({ relatedId: this.id, type: item.type, amount: item.amount, content: item.content })
        )
      })
      Promise.all(applicationPromise).then(() => {
        this.$emit('on-submit')
      })
    },
    getContractDetail () {
      getStatementDetail({ statementId: this.id }).then(res => {
        this.statement = res
        this.firstParty = res.firstPartyInfo
        this.secondParty = res.secondPartyInfo
        this.$emit('on-init', res)
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    getApplications () {
      getInvoiceApplicationsList({ relatedId: this.id }).then(res => {
        this.applications = res
      })
    },
    handleApplicationUpdated (applications) {
      this.newApplications = applications
    },
    handleInvalid (application) {
      let desc = ''
      this.$Modal.confirm({
        title: '撤回提示',
        render: h => {
          return h('Input', {
            attrs: {
              placeholder: '请输入撤回备注'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': event => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          invalidInvoiceApplication({ invoiceapplicationId: application.id, invalidMark: desc }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '撤销成功' })
              this.getApplications()
            }
          })
        }
      })
    }

  },
  created () {
    this.getContractDetail()
    this.getApplications()
  },
  computed: {
    unInvoicedAmount: function () {
      let total = 0
      this.applications.filter(x => x.status !== -1).forEach(item => {
        total += item.amount
      })

      return this.statement.amount - total
    }

  }
}
</script>
